// InstantPay.js
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const InstantPay = ({ onComplete }) => {
  const [step, setStep] = useState(0);
  const steps = [
    "Looking for your location...",
    "Parking location found!",
    "Getting parking special price...",
    "Parking price found!"
  ];
  const [price, setPrice] = useState(null);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (step < steps.length - 1) {
        setStep(step + 1);
      } else if (step === steps.length - 1) {
        setPrice((Math.random() * 10 + 5).toFixed(2));
        navigator.geolocation.getCurrentPosition(
          (position) => setLocation(position.coords),
          (error) => console.error("Error getting location:", error)
        );
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [step]);

  const circleVariants = {
    start: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1, transition: { duration: 1, ease: "easeInOut" } },
    exit: { scale: 0, opacity: 0, transition: { duration: 0.5, ease: "easeInOut" } }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-blue-500 flex items-center justify-center z-50"
    >
      <div className="text-white text-center">
        <AnimatePresence mode="wait">
          <motion.div
            key={step}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="mb-8"
          >
            <h2 className="text-3xl font-bold mb-4">{steps[step]}</h2>
            {step === steps.length - 1 && price && (
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.5, type: "spring", stiffness: 200, damping: 10 }}
              >
                <p className="text-5xl font-bold mb-8">Price: ${price}</p>
                <button
                  onClick={() => onComplete(price, location)}
                  className="px-6 py-3 bg-white text-blue-500 rounded-full text-xl font-bold hover:bg-blue-100 transition-colors"
                >
                  CONTINUE
                </button>
              </motion.div>
            )}
          </motion.div>
        </AnimatePresence>

        <div className="relative w-48 h-48 mx-auto">
          <AnimatePresence>
            {step < steps.length - 1 && (
              <>
                <motion.div
                  key="circle1"
                  variants={circleVariants}
                  initial="start"
                  animate="animate"
                  exit="exit"
                  className="absolute inset-0 border-4 border-white rounded-full"
                />
                <motion.div
                  key="circle2"
                  variants={circleVariants}
                  initial="start"
                  animate="animate"
                  exit="exit"
                  transition={{ delay: 0.5 }}
                  className="absolute inset-4 border-4 border-white rounded-full"
                />
                <motion.div
                  key="circle3"
                  variants={circleVariants}
                  initial="start"
                  animate="animate"
                  exit="exit"
                  transition={{ delay: 1 }}
                  className="absolute inset-8 border-4 border-white rounded-full"
                />
                <motion.div
                  key="dot"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 1.5, type: "spring", stiffness: 200, damping: 10 }}
                  className="absolute inset-16 bg-white rounded-full"
                />
              </>
            )}
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
};

export default InstantPay;