import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ParkingForm from './ParkingForm';

function App() {
  return (
    <GoogleOAuthProvider clientId="1085877535064-kqa4paf76qi552tltp3kmq0kkqkn3vop.apps.googleusercontent.com">
      <div className="App">
        <ParkingForm />
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
